import React from 'react';
import '../styles/faq.css';
import Header from './header';


const Guide = ()=>{
	localStorage.setItem('canAccessRegister','false');
	
	return(
		<div className="guidePage">
			<Header />
			<div className='v1'>
				<p className='pForVguide'>Toturial for website visitors</p>
				<iframe width="500" height="400" src="https://youtube.com/embed/kHUl32ZhgbM" allowFullScreen></iframe>
			</div>
			
			<div className='v2'>
				<p className='pForVguide'>Toturial for pediatrician to use the patient diagonsing system</p>
				<iframe width="500" height="400" src="https://youtube.com/embed/f2OzrWGweHw" allowFullScreen></iframe>
			</div>
		 </div>
	);
};

export default Guide;
